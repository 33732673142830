import {
  observable,
  action,
  runInAction,
  computed,
  makeObservable,
} from 'mobx';
import axios from 'axios';
import {
  lowerCase,
  upperCase,
  getRandomId,
  isBtcLimitAddress,
} from 'common/utils';
import Ts from 'components/LocaleTranslator';
import { message } from 'antd';
import { MULT_ADDRESS_OP_TYPE } from 'common/utils/constants';

/**
 * 设置子账户相关，必须提供 puid 和 coinType
 * 分两种情况：
 * 1.为当前登录的子账户进行设置，puid 和 coinType 去 UserStore获取
 * 2.从子账户列表点击“设置”进行子账户设置，puid 和 coinType 通过url链接参数获取
 */
class SettingsStore {
  //#region Overview
  // 当前要设置的子账户的 puid
  @observable puid = null;

  // 当前要设置的子账户的币种 currentCoinType
  @observable currentCoinType = '';

  // 当前要设置的子账户的详情
  // v1/account/sub-account/info?puid=
  @observable currentSubAccountInfo = {};

  // 当前要设置的子账户的报警信息
  // v1/sub-account/alert/info?puid=
  @observable subAccountAlertInfo = {};

  @observable loading = false;
  //#endregion

  //#region 收款地址和最小起付金额 wallet address & minimum payment
  @observable
  modalOpType = 'address-input';
  @observable
  modifyModalVisible = false;
  @observable
  verifyUuid = '';
  @observable
  verifyCode = '';
  @observable
  validate = {
    newAddress: '',
    update: '',
  };
  @observable minPayValue = 0.001;
  @observable minPayForAll = false;
  //#endregion

  //#region 共享数据 包括观察者链接和守护者设置 share to watcher
  @observable watcherList = [];
  @observable currentWatcherId = null;
  //#endregion

  //#region 报警设置 Alert Setting
  @observable loadingAlert = false;
  @observable loadingContactList = false;
  @observable contactList = [];
  @observable loadingNewContactList = {
    telegram: false,
    phone: false,
    email: false,
  };
  @observable newContactList = { telegram: [], phone: [], email: [] };
  @observable newContactType = 'telegram';
  @observable alertModalVisible = false;
  @observable alertModalOpType = 'contact-create';
  @observable currentUpdateAlertContact = {};
  //#endregion

  //#region 已BTC支付 Pay with BTC
  @observable loadingChangePaywithBtc = false;
  //#endregion

  //#region 闪兑
  @observable otcInfo = {
    type: '', // 当前结算方式， otc:闪兑  origin:原币
    otc: {
      // 闪兑的配置信息
      coins: [],
    },
    origin: {
      // 原币兑换的配置信息
      coins: [],
    },
  };
  @observable otcType = 'otc';
  @observable otcModalCoin = '';
  //#endregion
  // 请求中
  @observable isReqLoading = false;

  /**
   * 要设置的当前子账户的币种
   */
  @action
  setCurrentCoinType = (coinType) => {
    this.currentCoinType = coinType;
  };

  /**
   * 要设置的当前子账户的 puid
   */
  @action
  setCurrentPuid = (puid) => {
    this.puid = puid;
  };

  /**
   * 当前要设置的子账户的详情:
   * v1/account/sub-account/info?puid=
   */
  @action
  getSubAccountInfo = async (puid) => {
    this.loading = true;
    // const accountRes = await axios.get(`account/info`, {
    //   params: {
    //     puid: puid ? puid : this.puid,
    //   },
    // });
    // if (accountRes && accountRes.data) {
    //   runInAction(() => {
    //     // 可以获取到收款地址、起付金额、联系方式等相关信息
    //     this.currentSubAccountInfo = {
    //       ...accountRes.data,
    //     };
    //   });
    // }

    const subAccountRes = await axios.get(`account/sub-account/info`, {
      params: {
        puid: puid ? puid : this.puid,
        version: 'v2',
      },
    });

    if (subAccountRes && subAccountRes.data) {
      runInAction(() => {
        this.currentSubAccountInfo = subAccountRes.data;
        this.loading = false;
      });
    }
  };
  /**
   * 当前要设置的子账户的警报信息
   * v1/sub-account/alert/info?puid=
   */
  @action
  getSubAccountAlertInfo = async () => {
    this.loadingAlert = true;
    const subAccountRes = await axios.get(`/sub-account/alert/info`, {
      params: {
        puid: this.puid,
        lang: localStorage.getItem('lang'),
      },
    });
    this.loadingAlert = false;

    if (subAccountRes && subAccountRes.data) {
      runInAction(() => {
        this.subAccountAlertInfo = subAccountRes.data;
      });
    }
  };

  constructor() {
    makeObservable(this);
  }

  // /**
  //  * 用户联系方式
  //  * @readonly
  //  */
  // @computed
  // get userContactTypeList() {
  //   let contactTypeList = [];
  //   const { contact } = this.currentSubAccountInfo;
  //   if (!contact) {
  //     return [];
  //   }
  //   if (contact.mail || contact.mail.length > 0) {
  //     contactTypeList.push({ type: 'mail', value: contact.mail });
  //   }
  //   if (contact.phone && contact.phone.number) {
  //     contactTypeList.push({
  //       type: 'sms',
  //       value: contact.phone ? contact.phone.number : '',
  //     });
  //   }

  //   return contactTypeList;
  // }

  /**
   * 当前子账户是否支持多地址
   */
  @computed
  get isSupportMultAddress() {
    if (
      this.currentSubAccountInfo &&
      this.currentSubAccountInfo.is_support_multi_address === 1
    ) {
      return true;
    }
    return false;
  }

  @action
  setModifyModalVisible = (isShow) => {
    this.modifyModalVisible = isShow;
  };

  /**
   * 设置操作类型
   * need-wait: 暂不可操作
   *
   */
  @action
  setModalOpType = (type) => {
    this.modalOpType = type;
  };

  //#region -- 收款地址设置 Set Wallet Address --

  @action
  resetValidate = () => {
    this.validate = {
      newAddress: '',
      update: '',
    };
  };

  /**
   * 验证地址非空
   */
  @action
  validateAddress = (newAddress) => {
    if (!newAddress) {
      let { newAddress, ...others } = this.validate;
      newAddress = new Ts().getLocaleString('pages.settings.emptyAddress');
      this.validate = { newAddress, ...others };
      return false;
    }
    return true;
  };

  /**
   * 验证验证码非空
   */
  @action
  validateVerifyCode = (verifyCode) => {
    if (!verifyCode) {
      let { update, ...others } = this.validate;
      update = new Ts().getLocaleString('pages.settings.emptyCode');
      this.validate = { update, ...others };
      return false;
    }
    return true;
  };

  /**
   * 发送验证码
   */
  @action
  triggerVerifyCode = (contactType = 'sms', successCallback, errorCallback) => {
    return new Promise(async (resolve, reject) => {
      try {
        // if (!this.validateAddress(newAddress)) {
        //   return;
        // }
        const res = await axios.get(`/account/verify-code/${contactType}`, {
          params: {
            puid: this.puid,
            address_type: '',
          },
        });
        if (res && res.data) {
          runInAction(() => {
            if (res.data.success) {
              this.verifyUuid = res.data.uuid;
              successCallback();
              resolve({ isSend: true });
            } else {
              if (res.data.retries_left > 0) {
                /* 频繁发送验证码，接口返回格式为：⊙﹏⊙ 
                  data:{
                    retries_left:30,
                    status:'发送频繁',
                    success:false,
                    uuid:'xxx'
                  }
                */
                let { update, ...others } = this.validate;
                update = res.data.status;
                this.validate = { update, ...others };
                resolve({ isSend: true });
              } else {
                errorCallback && errorCallback({ isSend: false });
                resolve({ isSend: false, message: '' });
              }
            }
          });
        } else {
          errorCallback && errorCallback({ isSend: false });
          resolve({ isSend: false, message: '' });
        }
      } catch (error) {
        errorCallback && errorCallback({ isSend: false });
        resolve({ isSend: false, message: error.message });
      }
    });
  };

  /**
   * 用于多地址的发送验证码
   */
  @action
  triggerMultAddressVerifyCode = (
    contactType = 'sms',
    actionType,
    successCallback,
    errorCallback,
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(
          `/multi-addr/send-verify-code/${contactType}`,
          {
            params: {
              puid: this.puid,
              action: actionType,
            },
          },
        );
        if (res && res.data) {
          runInAction(() => {
            if (res.data.success) {
              this.verifyUuid = res.data.uuid;
              successCallback();
              resolve({ isSend: true });
            } else {
              if (res.data.retries_left > 0) {
                /* 频繁发送验证码，接口返回格式为：⊙﹏⊙ 
                  data:{
                    retries_left:30,
                    status:'发送频繁',
                    success:false,
                    uuid:'xxx'
                  }
                */
                let { update, ...others } = this.validate;
                update = res.data.status;
                this.validate = { update, ...others };
                resolve({ isSend: true });
              } else {
                errorCallback && errorCallback({ isSend: false });
                resolve({ isSend: false, message: '' });
              }
            }
          });
        } else {
          errorCallback && errorCallback({ isSend: false });
          resolve({ isSend: false, message: '' });
        }
      } catch (error) {
        errorCallback && errorCallback({ isSend: false });
        resolve({ isSend: false, message: error.message });
      }
    });
  };
  /**
   *联合挖矿地址更新
   */
  @action
  updateMergeMiningAddress = async (
    verify_code,
    verify_mode,
    totp_verify_code,
    new_address,
    successCallback,
    errorCallback,
    MergeMiningCoinType,
  ) => {
    try {
      const puid = localStorage.getItem('puid');
      const res = await axios.post(
        `/account/merged-mining/${MergeMiningCoinType}/address/update`,
        {
          puid: puid,
          verify_code,
          verify_mode,
          totp_verify_code,
          verify_id: this.verifyUuid,
          new_address,
        },
      );
      if (res && res.data) {
        runInAction(() => {
          successCallback();
        });
      }
    } catch (error) {
      if (error.data && error.data.new_address) {
        let { newAddress, ...others } = this.validate;
        this.validate = { newAddress: error.msg, ...others };
      } else {
        let { update, ...others } = this.validate;
        this.validate = { update: error.msg, ...others };
      }
      if (errorCallback) {
        errorCallback(error.msg);
      }
    }
  };

  /**
   * confirmUpdate
   */
  @action
  updateAddress = async (
    verify_code,
    verify_mode,
    new_address,
    successCallback,
    errorCallback,
  ) => {
    try {
      const res = await axios.post(`/account/address/update`, {
        puid: this.puid,
        verify_code,
        verify_mode,
        verify_id: this.verifyUuid,
        new_address,
      });
      if (res && res.data) {
        runInAction(() => {
          successCallback();
        });
      }
    } catch (error) {
      if (error.data.new_address) {
        let { newAddress, ...others } = this.validate;
        this.validate = { newAddress: error.msg, ...others };
      } else {
        let { update, ...others } = this.validate;
        this.validate = { update: error.msg, ...others };
      }
      if (errorCallback) {
        errorCallback(error.msg);
      }
    }
  };
  //#endregion

  //#region -- 多地址收款 --
  @observable loadingMultAddressList = false;
  @observable.deep multAddressList = [];
  @observable.deep multAddressOpType = MULT_ADDRESS_OP_TYPE.None; // constants: MULT_ADDRESS_OP_TYPE
  @observable.deep loadingAddMultAddress = false;
  @observable originMultAddressList = [];

  @action
  getMultAddressList = async () => {
    try {
      this.loadingMultAddressList = true;
      const res = await axios.get(`/multi-addr/list`, {
        params: {
          puid: this.puid,
        },
      });
      this.loadingMultAddressList = false;
      if (res && res.data && res.data.list) {
        runInAction(() => {
          this.multAddressList = res.data.list.map((item) => ({
            ...item,
            serverSide: true,
            clientUniqueKey: getRandomId(),
            clientMinPay: item.pay_limit,
            clientProportion: item.divide_value,
            clientRemark: item.remark,
          }));
          this.originMultAddressList = JSON.parse(
            JSON.stringify(this.multAddressList),
          );
        });
      }
    } catch (error) {
      this.loadingMultAddressList = false;
      this.multAddressList = [];
      this.originMultAddressList = [];
      console.log(error);
    }
  };

  @action
  setMultAddressOpType = async (opType) => {
    this.multAddressOpType = opType;
  };

  @action
  updateMultAddressList = async (list) => {
    this.multAddressList = list;
  };

  @action
  revertMultAddressList = async () => {
    // this.multAddressList = this.multAddressList
    //   .filter((item) => item.serverSide)
    //   .map((item) => ({ ...item, clientAddressEditable: false }));
    this.multAddressList = JSON.parse(
      JSON.stringify(this.originMultAddressList),
    );
  };

  @action
  resetMultAddressData = () => {
    this.setMultAddressOpType(MULT_ADDRESS_OP_TYPE.None);
    this.multAddressList = [];
  };

  @action
  filterMultAddressList = async (filter) => {
    const filteredList = this.multAddressList.filter(filter);
    const serverSideCount = filteredList.filter(
      (item) => item.serverSide,
    ).length; // 来自服务端的数量
    this.multAddressList = filteredList.map((item, index) => {
      if (item.serverSide) {
        return item;
      } else {
        // 重新设置client side 的序号，为了placeholder显示
        return { ...item, clientSeq: index - serverSideCount + 1 };
      }
    });
  };

  /**
   * 新增多地址，包括已有的地址也一并发送到服务端
   * "address_list":[ { "sub_address":, "divide_value":, "pay_limit":, "remark": } ... ],
   * @param {*} address_list
   */
  @action addMultAddressValidate = async (
    address_list = [],
    successCallback,
    errorCallback,
  ) => {
    if (upperCase(this.currentCoinType) === 'BTC') {
      let isInValid = false;
      address_list.forEach((item) => {
        isInValid = isInValid || isBtcLimitAddress(item.sub_address);
      });
      if (isInValid) {
        let limitAddress = new Ts().getLocaleString(
          'pages.settings.multAddress.validate.limitAddress',
        );
        errorCallback && errorCallback(limitAddress);
        return;
      }
    }
    try {
      this.loadingAddMultAddress = true;
      const res = await axios.post(`/multi-addr/validate`, {
        puid: this.puid,
        address_list: address_list.map((item) => {
          return {
            sub_address: item.sub_address,
            divide_value: item.divide_value,
            pay_limit: item.pay_limit,
            remark: item.remark,
          };
        }),
        divide_mode: 1, //分配模式:1按比例
      });
      this.loadingAddMultAddress = false;
      if (res && res.data && res.data.status) {
        successCallback && successCallback();
      }
    } catch (error) {
      this.loadingAddMultAddress = false;
      errorCallback && errorCallback(error.msg);
      console.log(error);
    }
  };

  /**
   * 新增多地址，包括已有的地址也一并发送到服务端
   * "address_list":[ { "sub_address":, "divide_value":, "pay_limit":, "remark": } ... ],
   * @param {*} address_list
   * @param {*} verify_code
   * @param {*} verify_mode
   */
  @action addMultAddressService = async (
    address_list,
    verify_code,
    verify_mode,
    totp_verify_code,
    successCallback,
    errorCallback,
  ) => {
    try {
      this.loadingAddMultAddress = true;
      const res = await axios.post(`/multi-addr/create`, {
        puid: this.puid,
        address_list: address_list.map((item) => {
          return {
            sub_address: item.sub_address,
            divide_value: item.divide_value,
            pay_limit: item.pay_limit,
            remark: item.remark,
          };
        }),
        divide_mode: 1, //分配模式:1按比例
        verify_code,
        verify_mode,
        totp_verify_code,
        verify_id: this.verifyUuid,
      });
      this.loadingAddMultAddress = false;
      if (res && res.data && res.data.status) {
        successCallback && successCallback();
      }
    } catch (error) {
      this.loadingAddMultAddress = false;
      errorCallback && errorCallback(error.msg);
      console.log(error);
    }
  };

  getUpdateServerType = (opType) => {
    switch (opType) {
      case MULT_ADDRESS_OP_TYPE.UpdateProportion:
        return 'scale';
      case MULT_ADDRESS_OP_TYPE.UpdateMinpay:
        return 'limit';
      default:
        return '';
    }
  };
  /**
   * 更新多地址（更新比例和起付金额），包括已有的地址也一并发送到服务端
   * "address_list":[ { "sub_address":, "divide_value":, "pay_limit":, "remark": } ... ],
   * @param {*} address_list
   * @param {*} verify_code
   * @param {*} verify_mode
   */
  @action updateMultAddressService = async (
    address_list,
    verify_code,
    verify_mode,
    totp_verify_code,
    successCallback,
    errorCallback,
  ) => {
    try {
      this.loadingAddMultAddress = true;
      const res = await axios.post(
        `/multi-addr/update/${this.getUpdateServerType(
          this.multAddressOpType,
        )}`,
        {
          puid: this.puid,
          address_list: address_list
            ? address_list.map((item) => {
                const {
                  clientMinPay,
                  clientProportion,
                  clientRemark,
                  clientUniqueKey,
                  ...rest
                } = item;
                return rest;
              })
            : [],
          divide_mode: 1, //分配模式:1按比例
          verify_code,
          verify_mode,
          totp_verify_code,
          verify_id: this.verifyUuid,
        },
      );
      this.loadingAddMultAddress = false;
      if (res && res.data && res.data.status) {
        successCallback && successCallback();
      }
    } catch (error) {
      this.loadingAddMultAddress = false;
      errorCallback && errorCallback(error.msg);
      console.log(error);
    }
  };

  /**
   * 删除单条地址前验证
   * @param {*} sub_address
   */
  @action deleteAddressValidate = async (
    sub_address,
    successCallback,
    errorCallback,
  ) => {
    try {
      const res = await axios.post(`/multi-addr/delete-validate`, {
        puid: this.puid,
        sub_address,
      });

      if (res && res.data && res.data.status) {
        // res.data.reason 删除验证  1:比例不为0 （不可删除）, 2地址有余额（可删除，需要选择转移余额的地址）
        successCallback &&
          successCallback(
            res.data.reason,
            res.data.address_balance ? res.data.address_balance : 0,
          );
      } else if (!res.data.status) {
        errorCallback && errorCallback(res.data.msg, res.data.reason);
      }
    } catch (error) {
      errorCallback && errorCallback(error.msg);
      console.log(error);
    }
  };

  /**
   * 删除单条地址
   * @param {*} sub_address
   * @param {*} verify_code
   * @param {*} verify_mode
   */
  @action deleteAddressService = async (
    sub_address,
    balanceInAddress,
    verify_code,
    verify_mode,
    totp_verify_code,
    successCallback,
    errorCallback,
  ) => {
    try {
      const res = await axios.post(`/multi-addr/delete`, {
        puid: this.puid,
        sub_address,
        in_address: balanceInAddress,
        verify_code,
        verify_mode,
        totp_verify_code,
        verify_id: this.verifyUuid,
      });
      if (res && res.data && res.data.status) {
        successCallback && successCallback(sub_address, res.data.reason);
      }
    } catch (error) {
      errorCallback && errorCallback(error.msg);
      console.log(error);
    }
  };

  /**
   * 修改单条地址的备注
   * @param {*} sub_address
   * @param {*} remark
   * @param {*} successCallback
   * @param {*} errorCallback
   */
  @action updateAddressRemark = async (
    sub_address,
    remark,
    successCallback,
    errorCallback,
  ) => {
    try {
      const res = await axios.post(`/multi-addr/update-remark`, {
        puid: this.puid,
        sub_address,
        remark,
      });
      if (res && res.data && res.data.status) {
        successCallback && successCallback();
      }
    } catch (error) {
      errorCallback && errorCallback(JSON.stringify(error.msg));
      console.log(error);
    }
  };

  /**
   * 修改单条地址服务端校验
   * @param {*} from_address
   * @param {*} to_address
   * @param {*} successCallback
   * @param {*} errorCallback
   */
  @action updateSingleAddressValidate = async (
    from_address,
    to_address,
    successCallback,
    errorCallback,
  ) => {
    if (
      upperCase(this.currentCoinType) === 'BTC' &&
      isBtcLimitAddress(to_address)
    ) {
      let limitAddress = new Ts().getLocaleString(
        'pages.settings.multAddress.validate.limitAddress',
      );
      errorCallback && errorCallback(limitAddress);
      return;
    }
    try {
      const res = await axios.post(`/multi-addr/change-validate`, {
        puid: this.puid,
        from_address,
        to_address,
      });
      if (res && res.data && res.data.status) {
        successCallback && successCallback();
      }
    } catch (error) {
      errorCallback && errorCallback(JSON.stringify(error.msg));
      console.log(error);
    }
  };

  /**
   * 修改单条地址
   * @param {*} from_address
   * @param {*} to_address
   * @param {*} successCallback
   * @param {*} errorCallback
   */
  @action updateSingleAddress = async (
    from_address,
    to_address,
    verify_code,
    verify_mode,
    totp_verify_code,
    successCallback,
    errorCallback,
  ) => {
    try {
      const res = await axios.post(`/multi-addr/change`, {
        puid: this.puid,
        from_address,
        to_address,
        verify_code,
        verify_mode,
        totp_verify_code,
        verify_id: this.verifyUuid,
      });
      if (res && res.data && res.data.status) {
        successCallback && successCallback();
      }
    } catch (error) {
      errorCallback && errorCallback(JSON.stringify(error.msg));
      console.log(error);
    }
  };

  /**
   * 修改比例、起付金额
   * @param {*} address_list
   * @param {*} verify_code
   * @param {*} verify_mode
   * @param {*} successCallback
   * @param {*} errorCallback
   */
  @action multAddressUpdateProportionAndMinPay = async (
    address_list,
    verify_code,
    verify_mode,
    successCallback,
    errorCallback,
  ) => {
    try {
      const res = await axios.post(`/multi-addr/update`, {
        puid: this.puid,
        address_list,
        divide_mode: 1, //分配模式:1按比例
        verify_code,
        verify_mode,
        verify_id: this.verifyUuid,
      });
      if (res && res.data && res.data.status) {
        successCallback && successCallback();
      }
    } catch (error) {
      errorCallback && errorCallback(error.msg);
      console.log(error);
    }
  };

  //#endregion

  //#region -- 起付金额设置 Set Min Pay --
  @action
  setMinPayValue = (value) => {
    this.minPayValue = value;
  };

  @action
  setMinPayForAll = (isForAll) => {
    this.minPayForAll = isForAll;
  };

  @action
  modifyMinPay = async (successCallback, errorCallback) => {
    try {
      const res = await axios.post(`/account/pay/limit`, {
        puid: this.puid,
        amount: this.minPayValue === 0.001 ? 0.005 : this.minPayValue,
        all_subaccount_limit: Number(this.minPayForAll),
      });
      if (res && res.data) {
        runInAction(() => {
          if (res.data.status === true) {
            successCallback();
          } else {
            errorCallback();
          }
        });
      }
    } catch (error) {
      errorCallback(error.msg);
    }
  };
  //#endregion

  //#region -- 观察者设置 Share to watcher
  @observable loadingWatcherList = false;
  @observable watcherOpModalVisible = false; // 创建、编辑观察者链接弹框
  @observable watcherOpType = false; // 创建、编辑观察者链接: create-watcher | edit-watcher
  @observable currentWatcherItem = null;

  @action
  /**
   * 创建、编辑观察者链接弹框
   */
  setWatcherOpModalVisible = (isShow) => {
    this.watcherOpModalVisible = isShow;
  };

  /**
   * 创建、编辑观察者链接: create-watcher | edit-watcher
   */
  @action
  setWatcherOpType = (type) => {
    this.watcherOpType = type;
  };

  @action
  getWatcherList = async () => {
    this.loadingWatcherList = true;
    const res = await axios.get(`/account/watcher/list`, {
      params: {
        puid: this.puid,
      },
    });
    this.loadingWatcherList = false;
    if (res && res.data) {
      runInAction(() => {
        this.watcherList = res.data;
      });
    }
  };

  @action
  createWatcher = async (
    note,
    authorities = [],
    successCallback,
    errorCallback,
  ) => {
    try {
      const res = await axios.post(`/account/watcher/create`, {
        puid: this.puid,
        note,
        authorities: authorities.join(','),
      });
      if (res && res.data) {
        runInAction(() => {
          if (res.data.status === true) {
            this.getWatcherList();
            this.setWatcherOpModalVisible(false);
            successCallback();
          }
        });
      }
    } catch (error) {
      message.error(error.msg);
      errorCallback(error.msg);
    }
  };

  @action
  editWatcher = async (
    watcher_id,
    authorities = [],
    successCallback,
    errorCallback,
  ) => {
    try {
      const res = await axios.post(`/account/watcher/update`, {
        puid: this.puid,
        watcher_id,
        authorities: authorities.join(','),
      });
      if (res && res.data) {
        runInAction(() => {
          if (res.data.status === true) {
            this.getWatcherList();
            this.setWatcherOpModalVisible(false);
            successCallback();
          }
        });
      }
    } catch (error) {
      message.error(error.msg);
      errorCallback(error.msg);
    }
  };

  /**
   * 当前操作的观察者ID
   */
  @action
  setCurrentWatcherId = (watcher_id) => {
    this.currentWatcherId = watcher_id;
  };

  /**
   * 当前操作的观察者
   */
  @action
  setCurrentWatcherItem = (watcherItem) => {
    this.currentWatcherItem = watcherItem;
  };

  @observable loadingDeleteWatcher = false;
  @action
  deleteWatcher = async (successCallback) => {
    this.loadingDeleteWatcher = true;
    try {
      const res = await axios.post(`/account/watcher/delete`, {
        puid: this.puid,
        watcher_id: this.currentWatcherId,
      });
      if (res && res.data) {
        runInAction(() => {
          if (res.data.status === true) {
            this.getWatcherList();
            this.loadingDeleteWatcher = false;
            successCallback();
          }
        });
      }
    } catch (error) {
      this.loadingDeleteWatcher = false;
      //errorCallback();
    }
  };

  @observable accessKey = '';
  @observable loadingGenerateAK = false;
  @action
  getAK = async () => {
    const res = await axios.get(`/account/access-key/get`, {
      params: {
        puid: this.puid,
      },
    });
    if (res && res.data) {
      runInAction(() => {
        this.accessKey = res.data.access_key;
      });
    }
  };

  @action
  generateAK = async () => {
    this.loadingGenerateAK = true;
    const res = await axios.post(`/account/access-key/set`);
    if (res && res.data) {
      runInAction(() => {
        this.accessKey = res.data.access_key;
        this.loadingGenerateAK = false;
      });
    }
  };

  //#endregion

  //#region -- 报警设置 Alert Setting
  @action
  getContactList = async () => {
    try {
      this.loadingContactList = true;
      const res = await axios.get(`/alert/contacts/my`, {
        params: {
          puid: this.puid,
        },
      });
      if (res && res.data) {
        runInAction(() => {
          this.contactList = res.data;
          this.loadingContactList = false;
        });
      }
    } catch (error) {
      this.loadingContactList = false;
    }
  };

  @action
  setAlertModalOpType = (opType) => {
    this.alertModalOpType = opType;
  };

  @action
  setAlertModalVisible = (isShow) => {
    this.alertModalVisible = isShow;
  };

  /**
   * 修改报警项
   */
  @action
  updateAlertItem = async (alertItem, successCallback, errorCallback) => {
    try {
      const res = await axios.post(`/alert/setting/update`, {
        puid: alertItem.puid || this.puid,
        hashrate_enabled: alertItem.hashrateEnabled,
        hashrate: alertItem.hashrate || 0,
        unit: alertItem.hashrateUnit,
        miners_enabled: alertItem.minersEnabled,
        miners: alertItem.miners || 0,
        interval: alertItem.alertInterval,
      });
      if (res && res.data) {
        runInAction(() => {
          if (res.data.status === true) {
            message.success(
              new Ts().getLocaleString('pages.settings.alertItemUpdateSuccess'),
            );
            successCallback();
          }
        });
      }
    } catch (error) {
      message.error(error.msg);
      if (errorCallback) {
        errorCallback(error.msg);
      }
    }
  };

  /**
   * 新增报警联系人
   */
  @action
  createAlertContact = async ({
    name: note,
    phone,
    phoneRegion,
    email,
    puid,
  }) => {
    if (this.isReqLoading) {
      return;
    }
    this.isReqLoading = true;
    try {
      const res = await axios.post(`/alert/contacts/create`, {
        puid: puid || this.puid,
        note,
        email,
        phone,
        region_code: phoneRegion.replace('+', ''),
      });
      this.isReqLoading = false;
      if (res && res.data) {
        runInAction(() => {
          if (res.data.status === true) {
            this.getContactList(puid || this.puid);
            this.setAlertModalVisible(false);
          }
        });
      }
    } catch (error) {
      this.isReqLoading = false;
      error.msg && message.error(error.msg);
    }
  };

  @action
  setCurrentUpdateAlertContact = (data) => {
    this.currentUpdateAlertContact = data;
  };

  /**
   * 编辑报警联系人
   */
  @action
  updateAlertContact = async ({
    id,
    name: note,
    phone,
    phoneRegion,
    email,
    puid,
  }) => {
    if (this.isReqLoading) {
      return;
    }
    this.isReqLoading = true;
    try {
      const res = await axios.post(`/alert/contacts/update`, {
        puid: puid || this.puid,
        id,
        note,
        email,
        phone,
        region_code: phoneRegion.replace('+', ''),
      });
      this.isReqLoading = false;
      if (res && res.data) {
        runInAction(() => {
          if (res.data.status === true) {
            this.getContactList(puid || this.puid);
            this.setAlertModalVisible(false);
          }
        });
      }
    } catch (error) {
      this.isReqLoading = false;
      error.msg && message.error(error.msg);
    }
  };

  /**
   * 删除报警联系人
   */
  @action
  deleteAlertContact = async ({ id, puid }) => {
    if (this.isReqLoading) {
      return;
    }
    this.isReqLoading = true;
    try {
      const res = await axios.post(`/alert/contacts/delete`, {
        puid: puid || this.puid,
        id,
      });
      this.isReqLoading = false;
      if (res && res.data) {
        runInAction(() => {
          if (res.data.status === true) {
            this.getContactList(puid || this.puid);
            this.setAlertModalVisible(false);
          }
        });
      }
    } catch (error) {
      message.error(error.msg);
      this.isReqLoading = false;
    }
  };

  //new alert start

  @action
  setNewContactType = (type) => {
    this.newContactType = type;
  };
  @action
  getNewAlertContactList = async ({ alertType }) => {
    try {
      this.loadingNewContactList[alertType] = true;
      const res = await axios.post(`/alert/contacts/${alertType}/list`, {
        params: {
          puid: this.puid,
        },
      });
      if (res && res.data) {
        runInAction(() => {
          this.newContactList[alertType] = res.data;
          this.loadingNewContactList[alertType] = false;
        });
      }
    } catch (error) {
      this.loadingNewContactList[alertType] = false;
    }
  };
  /**
   * 新增报警联系人
   */
  @action
  createNewAlertContact = async ({
    remark: note,
    phone,
    phoneRegion,
    email,
    puid,
    alertType,
    chatId,
  }) => {
    if (this.isReqLoading) {
      return;
    }
    this.isReqLoading = true;
    try {
      let params = {
        puid: puid || this.puid,
        note,
      };
      if (alertType === 'email') {
        params.email = email;
      } else if (alertType === 'phone') {
        params.phone = phone;
        params.region_code = phoneRegion.replace('+', '');
      } else if (alertType === 'telegram') {
        params.chatid = chatId;
      }
      const res = await axios.post(
        `/alert/contacts/${alertType}/create`,
        params,
      );
      this.isReqLoading = false;
      if (res && res.data) {
        runInAction(() => {
          if (res.data.status === true) {
            this.getNewAlertContactList({ alertType });
            this.setAlertModalVisible(false);
          }
        });
      }
    } catch (error) {
      this.isReqLoading = false;
      error.msg && message.error(error.msg);
    }
  };

  /**
   * 编辑报警联系人
   */
  @action
  updateNewAlertContact = async ({
    id,
    remark: note,
    phone,
    phoneRegion,
    email,
    puid,
    alertType,
    chatId,
  }) => {
    if (this.isReqLoading) {
      return;
    }
    this.isReqLoading = true;
    try {
      let params = {
        id,
        puid: puid || this.puid,
        note,
      };
      if (alertType === 'email') {
        params.email = email;
      } else if (alertType === 'phone') {
        params.phone = phone;
        params.region_code = phoneRegion.replace('+', '');
      } else if (alertType === 'telegram') {
        params.chatid = chatId;
      }
      const res = await axios.post(
        `/alert/contacts/${alertType}/update`,
        params,
      );
      this.isReqLoading = false;
      if (res && res.data) {
        runInAction(() => {
          if (res.data.status === true) {
            this.getNewAlertContactList({ alertType });
            this.setAlertModalVisible(false);
          }
        });
      }
    } catch (error) {
      this.isReqLoading = false;
      error.msg && message.error(error.msg);
    }
  };

  /**
   * 删除报警联系人
   */
  @action
  deleteNewAlertContact = async ({ id, puid, alertType }) => {
    if (this.isReqLoading) {
      return;
    }
    this.isReqLoading = true;
    try {
      const res = await axios.post(`/alert/contacts/${alertType}/delete`, {
        puid: puid || this.puid,
        id,
      });
      this.isReqLoading = false;
      if (res && res.data) {
        runInAction(() => {
          if (res.data.status === true) {
            this.getNewAlertContactList({ alertType });
            this.setAlertModalVisible(false);
          }
        });
      }
    } catch (error) {
      message.error(error.msg);
      this.isReqLoading = false;
    }
  };
  //new alert end
  //#endregion

  //#region -- 已btc结算 Pay with BTC

  @observable paywithModalVisible = false;
  @observable paywithModalOpType = 'paywithbtc-address-input';
  @observable paywithData = { enabled: 0 };
  @observable paywithCoin = 'btc';

  @observable coinCurrencyRate = null;

  /**
   * 获取当前币种(coinType)以某种币种(paywithCoin)支付的信息
   */
  @action
  getPaywithData = async (coinType, paywithCoin) => {
    //this.paywithData = { enabled: true, address: 'xx--xxx' };
    coinType = lowerCase(coinType);
    const res = await axios.get(`/otc/options`, {
      params: { otc_name: coinType, puid: this.puid },
    });
    if (res && res.data && res.data.list) {
      runInAction(() => {
        this.paywithData = res.data.list[paywithCoin]; // {enabled, pool_enabled, address}
      });
    }
  };

  /**
   * 获取当前币种的汇率
   */
  @action
  getCurrencyRate = async (coinType) => {
    coinType = lowerCase(coinType);
    const res = await axios.get(`/otc/rates`, {
      params: { otc_name: coinType, puid: this.puid },
    });
    if (res && res.data) {
      runInAction(() => {
        if (res.data.list && res.data.list[upperCase(coinType)]) {
          let rate = res.data.list[upperCase(coinType)];
          this.coinCurrencyRate = rate; // {BTC:{BTC, FIAT, CNY,USD,last_updated_at}}
        }
      });
    }
  };

  /**
   * 更新以某币种支付的信息 ：开启、地址
   */
  @action
  updatePaywithInfo = async (
    {
      verifyCode: verify_code,
      userContactType: verify_mode,
      paywithCoin,
      enabled,
      address,
    },
    successCallback,
    errorCallback,
  ) => {
    paywithCoin = lowerCase(paywithCoin);
    let verify_id = this.verifyUuid ? this.verifyUuid : undefined;
    try {
      const res = await axios.post(`/otc/options`, {
        puid: this.puid,
        verify_code,
        verify_mode,
        verify_id,
        coins: {
          [paywithCoin]: {
            enabled,
            address,
          },
        },
      });
      if (res && res.data) {
        runInAction(() => {
          if (res.data.status === true) {
            successCallback && successCallback();
            this.setPaywithModalVisible(false);
          }
        });
      }
    } catch (error) {
      console.log(error);
      if (error.data.new_address) {
        let { newAddress, ...others } = this.validate;
        this.validate = { newAddress: error.msg, ...others };
      } else {
        let { update, ...others } = this.validate;
        this.validate = { update: error.msg, ...others };
      }
      errorCallback && errorCallback();
    }
  };

  @action
  setPaywithModalOpType = (opType) => {
    this.paywithModalOpType = opType;
  };

  @action
  setPaywithModalVisible = (isShow) => {
    this.paywithModalVisible = isShow;
  };

  //#endregion

  //#region 算力一键切换 One Switch

  /*
   oneSwitchModalOpType:selected-mode | switching | switched-failed | switched-sucess
   
   */
  @observable oneSwitchModalOpType = 'selected-mode';
  @observable oneSwitchModalVisible = false;
  @observable loadingSwitching = false;

  @action
  setOneSwitchModalOpType = (opType) => {
    this.oneSwitchModalOpType = opType;
  };

  @action
  setOneSwitchModalVisible = (isShow) => {
    this.oneSwitchModalVisible = isShow;
  };

  /**
   * 切换算力
   */
  @action
  switchHashrate = async (
    { source, dest, puid = this.puid },
    successCallback,
    errorCallback,
  ) => {
    this.loadingSwitching = true;
    try {
      const res = await axios.post(`/change/hashrate`, {
        puid: puid,
        source,
        dest,
      });
      this.loadingSwitching = false;
      if (res && res.data) {
        runInAction(() => {
          if (res.data.dest_puid) {
            successCallback && successCallback(res.data);
          }
        });
      }
    } catch (error) {
      let msg = error.msg || '';
      console.log(error);
      this.loadingSwitching = false;
      errorCallback && errorCallback(msg);
    }
  };
  //#endregion

  //#region 闪兑

  @observable loadingSubmitSettle = false;
  /**
   * 提交结算模式
   */
  @action
  submitSettleType = async (
    { type, otc = { quote_coin_all: 'btc', deal_history: 1 } },
    successCallback,
    errorCallback,
  ) => {
    try {
      this.loadingSubmitSettle = true;
      let parmas = {
        puid: this.puid,
        type,
        status: 1,
      };
      if (type === 'otc') {
        parmas.otc = otc;
      }
      const res = await axios.post(`/account/address/setsettletype`, parmas);
      this.loadingSubmitSettle = false;
      if (res && res.data) {
        this.otcInfo = { ...this.otcInfo, type: res.data.type };
        runInAction(() => {
          successCallback && successCallback();
        });
      }
    } catch (error) {
      this.loadingSubmitSettle = false;
      if (errorCallback) {
        errorCallback(error.msg);
      }
    }
  };

  @observable loadingSettleType = false;
  /**
   * 获取闪兑结算数据
   *
   * @memberof SettingsStore
   */
  @action
  getSettletype = async () => {
    try {
      this.loadingSettleType = true;
      const res = await axios.get(`/account/address/getsettletype`, {
        params: { puid: this.puid },
      });
      this.loadingSettleType = false;
      if (res && res.data) {
        runInAction(() => {
          this.otcInfo = res.data;
          this.setOTCType(this.otcInfo.type);
        });
      }
    } catch (error) {
      this.loadingSettleType = false;
    }
  };

  @action
  setOTCType = (otcType) => {
    this.otcType = otcType;
  };

  @action
  setOTCInfoType = (type) => {
    this.otcInfo.type = type;
  };

  @action
  setOTCModalCoin = (coinType) => {
    this.otcModalCoin = coinType;
  };

  @observable loadingGetSmartCoinAddress = false;
  @observable smartCoinAddressList = [];
  @action
  getAddressListForSmartPool = async (coin_type) => {
    try {
      this.loadingGetSmartCoinAddress = true;
      const res = await axios.get(
        `/multi-addr/smartcoin-address/${coin_type}`,
        {
          params: { puid: this.puid },
        },
      );
      this.loadingGetSmartCoinAddress = false;
      if (res && res.data) {
        runInAction(() => {
          this.smartCoinAddressList = res.data;
        });
      }
    } catch (error) {
      this.loadingGetSmartCoinAddress = false;
    }
  };

  //#endregion
}

const settingsStore = new SettingsStore();

export { settingsStore };
